import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import ProductNav from "../components/product-nav";
import Banner from "../components/banner";
import Booking from "../components/booking";
import Presentation from "../components/presentation";
import Benefits from "../components/benefits";
import Slideshow from "../components/slideshow";
import Preparation from "../components/preparation";
import Questions from "../components/questions";
import Products from "../components/products";
import ImageWithText from "../components/image-with-text";

const ProductTemplate = ({ data }) => {
  const {
    id,
    title,
    seo,
    destination,
    level,
    featuredImage,
    logo,
    intro,
    banner,
    bookingContent,
    bookingUrl,
    imagesWithText,
    fullWidthImage,
    preparation,
    questions,
    quotations,
    seoSection,
    noIndex,
  } = data.product;

  const filterProducts = (product) => id !== product.id;
  const relatedProducts = destination.products?.filter(filterProducts);
  const seoTitle = seo?.title || title;
  const seoDescription = seo?.description?.description || intro?.intro;
  const seoImage = seo?.image || featuredImage;
  const canonicalURL = seo?.canonicalURL;

  const colors = {
    destination: destination.color || "var(--color-dark-yellow)",
    level: level?.color || "var(--color-black)",
  };

  return (
    <Layout whiteHeader={featuredImage} alert={!destination.partner}>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        colors={colors}
        noIndex={noIndex}
        canonicalURL={canonicalURL}
      />
      <Hero
        title={destination.title}
        logo={logo}
        intro={intro?.intro}
        media={featuredImage}
        badge={destination.badge}
        reviews={true}
      />
      <ProductNav product={data.product} />
      <Banner
        heading={banner?.heading}
        text={banner?.text}
        link={banner?.link}
        linkText={banner?.linkText}
        active={banner?.active}
        buttonLink={banner?.buttonLink}
        product
      />
      <div id="presentation">
        <Booking url={bookingUrl}>{bookingContent}</Booking>
        <Presentation product={data.product} />
        {fullWidthImage && (
          <GatsbyImage
            image={fullWidthImage.gatsbyImageData}
            alt={fullWidthImage.title}
          />
        )}
      </div>
      <div id="benefits">
        <Benefits product={data.product} />
        <Slideshow slides={imagesWithText} fullWidth product />
      </div>
      <Preparation preparation={preparation} />
      <Questions questions={questions} product />
      <Slideshow slides={quotations} />
      <Products products={relatedProducts} badge={destination.badge} related />
      <ImageWithText
        image={seoSection?.image}
        mobileImage={seoSection?.mobileImage}
        imageAlt={seoSection?.imageAlt}
        text={seoSection?.text}
      />
    </Layout>
  );
};

export default ProductTemplate;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    product: contentfulProduct(
      contentful_id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      id
      title
      seo {
        canonicalURL
        title
        description {
          description
        }
        image {
          description
          title
          gatsbyImageData(
            width: 1200
            height: 1200
            quality: 80
            resizingBehavior: FILL
          )
        }
      }
      noIndex
      featuredImage {
        description
        gatsbyImageData(layout: FULL_WIDTH)
        title
      }
      level {
        title
        color
      }
      price
      logo {
        description
        title
        gatsbyImageData(
          width: 300
          height: 130
          quality: 80
          resizingBehavior: PAD
        )
      }
      intro {
        intro
      }
      banner {
        heading
        text {
          raw
        }
        link
        linkText
        active
        buttonLink {
          id
          button
          buttonBackgroundColour
          textColour
          title
          link
          target
        }
      }
      bookingContent {
        raw
      }
      bookingUrl
      destination {
        title
        partner
        dateRange
        color
        products: product {
          id
          contentful_id
          destination {
            slug
          }
          level {
            title
            slug
            color
            order
          }
          featuredImage {
            description
            gatsbyImageData(layout: FULL_WIDTH)
            title
          }
          intro {
            intro
          }
          duration
          distance
          elevation
          difficulty
          price
        }
        intro {
          intro
        }
        badge {
          description
          title
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      duration
      distance
      elevation
      difficulty
      presentation {
        raw
      }
      fullWidthImage {
        description
        title
        gatsbyImageData(layout: FULL_WIDTH)
      }
      includedAtTheCamp
      includedOnGravel
      includedBeyondGravel
      notIncluded
      imagesWithText {
        contentful_id
        image {
          description
          title
          gatsbyImageData(layout: FULL_WIDTH)
        }
        mobileImage {
          title
          gatsbyImageData(quality: 80)
        }
        imageAlt
        text {
          raw
        }
      }
      preparation {
        raw
        references {
          contentful_id
          __typename
          image {
            description
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          mobileImage {
            title
            gatsbyImageData(quality: 80)
          }
          imageAlt
          text {
            raw
          }
        }
      }
      questions {
        contentful_id
        title
        category {
          title
        }
        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              description
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      quotations {
        contentful_id
        image {
          description
          title
          gatsbyImageData(layout: FULL_WIDTH)
        }
        mobileImage {
          title
          gatsbyImageData(quality: 80)
        }
        imageAlt
        text {
          raw
        }
        link
      }
      seoSection {
        image {
          description
          title
          gatsbyImageData(layout: FULL_WIDTH)
        }
        mobileImage {
          title
          gatsbyImageData(quality: 80)
        }
        imageAlt
        text {
          raw
        }
      }
    }

    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
