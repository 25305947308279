import * as React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedMessage, useIntl } from "react-intl";
import SwiperCore, { A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Bike from "./bike";
import Health from "./health";
import ButtonLink from "./button-link";
import { renderRichText } from "gatsby-source-contentful/rich-text";

SwiperCore.use([A11y]);

const query = graphql`
  query {
    levels: allContentfulLevel(sort: { fields: order }) {
      nodes {
        node_locale
        contentful_id
        title
        order
        gauge {
          title
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        illustration {
          description
          title
          gatsbyImageData(quality: 80)
        }
        intro {
          intro
        }
        color
        practice
        healthCondition
        duration
        distance
        elevation
        terrain
        accomodation
        participants
        order
        buttonLink {
          id
          button
          buttonBackgroundColour
          textColour
          title
          link
          target
        }
      }
    }
  }
`;

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const [{ value }] = node.content;

      if (value !== "" || node.content.length > 1) {
        return <p>{children}</p>;
      }

      return null;
    },
  },

  renderText: (text) => {
    return text
      .split("\n")
      .flatMap((text, index) => [index > 0 && <br key={index} />, text]);
  },
};

const Compare = ({ heading, intro }) => {
  const { locale, formatMessage } = useIntl();
  const data = useStaticQuery(query);
  const isActiveLocale = ({ node_locale }) => node_locale === locale;
  const levels = data.levels.nodes.filter(isActiveLocale);
  const [tab, setTab] = React.useState(0);
  console.log(intro);

  return (
    <div className="compare">
      <div className="container">
        {heading && (
          <div className="compare-header">
            <h2 className="heading heading-large">
              <FormattedMessage id="productsCompare" />
            </h2>
            <ul // eslint-disable-line
              role="list"
            >
              <li>
                <button
                  className={tab === 0 ? "active" : null}
                  onClick={() => setTab(0)}
                >
                  <FormattedMessage id="compareInPractice" />
                </button>
              </li>
              <li>
                <button
                  className={tab === 1 ? "active" : null}
                  onClick={() => setTab(1)}
                >
                  <FormattedMessage id="compareInFigures" />
                </button>
              </li>
            </ul>
          </div>
        )}
        {intro && (
          <div className="compare-intro">
            {renderRichText(intro, renderOptions)}
          </div>
        )}
        <Swiper
          className="compare-slideshow"
          slidesPerView="auto"
          spaceBetween={22}
        >
          {levels.map((level) => (
            <SwiperSlide
              key={level.contentful_id}
              style={{ "--color-level": level?.color }}
            >
              <div hidden={tab === 0 ? null : true}>
                <div>
                  <GatsbyImage
                    image={level.illustration.gatsbyImageData}
                    alt={level.illustration.title}
                  />
                </div>
                <img
                  src={level.gauge.file.url}
                  alt={level.gauge.title}
                  width={level.gauge.file.details.image.width}
                  height={level.gauge.file.details.image.height}
                  loading="lazy"
                  decoding="async"
                />
                <h3>{level.title}</h3>
                {level.intro && <p>{level.intro.intro}</p>}
                <dl aria-label={formatMessage({ id: "compareInPractice" })}>
                  <div>
                    <dt>
                      <Bike />
                    </dt>
                    <dd>{level.practice}</dd>
                  </div>
                  <div>
                    <dt>
                      <Health />
                    </dt>
                    <dd>{level.healthCondition}</dd>
                  </div>
                </dl>
              </div>
              <div className="figures" hidden={tab === 1 ? null : true}>
                <dl
                  className=""
                  aria-label={formatMessage({ id: "compareInFigures" })}
                >
                  <div>
                    <dt>
                      <FormattedMessage id="compareDuration" />
                    </dt>
                    <dd>{level.duration}</dd>
                  </div>
                  <div>
                    <dt>
                      <FormattedMessage id="compareDistance" />
                    </dt>
                    <dd>{level.distance}</dd>
                  </div>
                  <div>
                    <dt>
                      <FormattedMessage id="compareElevation" />
                    </dt>
                    <dd>{level.elevation}</dd>
                  </div>
                  <div>
                    <dt>Terrain</dt>
                    <dd>{level.terrain}</dd>
                  </div>
                  <div>
                    <dt>
                      <FormattedMessage id="compareAccomodation" />
                    </dt>
                    <dd>{level.accomodation}</dd>
                  </div>
                  <div>
                    <dt>Participants</dt>
                    <dd>{level.participants}</dd>
                  </div>
                </dl>
              </div>
              <ButtonLink
                href={level.buttonLink.link}
                button={level.buttonLink.button}
                backgroundColor={level.buttonLink.buttonBackgroundColour}
                textColor={level.buttonLink.textColour}
                target={level.buttonLink.target}
                className="compare-button"
              >
                {level.buttonLink.title}
              </ButtonLink>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Compare;
